import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';

// reactstrap components
import { Button, Card, Form, Input, Container, Row, Col, } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import api from "services/api";
import { login } from "services/auth";
import { event } from "jquery";



class RegisterPage extends Component {
  state = {
    email: "",
    senha: "",
    error: "",
    user:[],
    filter:[],
    id_unidade: "",
    tipo: "",
    nome:  "",
    endereco: "",
    complemento: "",
    bairro: "",
    cidade: "",
    cep: "",
    status: "", 
  };


  async componentDidMount() {

    const user = JSON.parse(localStorage.getItem('user'));

    
    axios.get(`https://sebstoreapi.app.br/api/user`)
    .then((response) => {
      const user = response.data;
      const filter = response.data;
      this.setState({
        user,
        filter
      })
    })
      
 

      axios.get(`https://sebstoreapi.app.br/api/unidade`)
      .then(res => {
        const unidade = res.data;
        this.setState({ unidade });
      })
            
  }



  handleSubmit = async event => {
    event.preventDefault();
    
    const users = JSON.parse(sessionStorage.getItem('user'));    
    console.log(users.id);
    console.log(users.name.givenName);
    console.log(users.emails[0].value);

    axios.post(`https://sebstoreapi.app.br/api/user`, {       
      id_unidade: "0",
      tipo: "0",
      nome: users.name.givenName,
      email: users.emails[0].value,
      senha: users.id,
      endereco: "0",
      complemento: "0",
      bairro: "0",
      cidade: "0",
      estado: "0",
      cep: "0",
      status: "1"   
    })
    .then(res => {
     
 
    })
    .catch(function (error) {
      //alert('Ocorreu algum erro!');
      console.log(error);
     });

     const email = users.emails[0].value;
     const senha = users.id;
     if (!email || !senha) {
       this.setState({ error: "* Preencha e-mail e senha para continuar!" });
     } else {
       try {
         const response = await api.post("/auth/authenticate", { email, senha });
         const token = response.data.token; 
         const userlog = response.data.user;
         const user = JSON.stringify(userlog);
 
         login(user, token);     
         console.log(user, token)   
       

        const user2 = JSON.parse(localStorage.getItem('user'));
        if (user2.tipo === "1" || user2.tipo === "2"){
          
          this.props.history.push("/index");
            window.location.reload();
        
          
          

        } else{

          this.props.history.push("/index");
          window.location.reload();

          // axios.post(`https://sebstore.app.br/api/boots`, { 
          //   user: user2._id,
          //   tipoboots: "64d7952da6c97e5c983b521f",
          //   boots: "80",
          // })
          // .then(res => {
          //   alert('Seja bem-vindo(a). Você ganhou 80 Tokens para já começar a trocar em produtos da nossa loja!');
          //   this.props.history.push("/index");
          //   window.location.reload();
          // })
          // .catch(function (error) {
          //   alert('Ocorreu algum erro!');
          //   console.log(error);
          //  });

          //  const config = {
          //   headers: {
          //     'Content-Type': 'application/json;charset=UTF-8',
          //     "Access-Control-Allow-Origin": "*",
          // }
          //};
        
        // axios.put(`https://sebstore.app.br/api/user/${user2._id}`, {  
        //   tipo: "2",
           
        //   }, config)
        //     .then(res => {
        //       this.props.history.push("/index");
        //     })
        //     .catch(function (error) {
        //       alert('Ocorreu algum erro!');
        //       console.log(error);
        //   });

        };

        
         
       } catch (err) {
         
         this.setState({
           error:
             "* Houve um problema com o login, por favor verifique suas credenciais ou entre em contato com o Eventos do Grupo SEB."
         });
       }
     }
    
     

    }

  

  handleSignIn = async e => {
    e.preventDefault();
    const { email, senha } = this.state;
    if (!email || !senha) {
      this.setState({ error: "* Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/auth/authenticate", { email, senha });
        const token = response.data.token; 
        const userlog = response.data.user;
        const user = JSON.stringify(userlog);

        login(user, token);     
        console.log(user, token)   
        this.props.history.push("/index");
        
      } catch (err) {
        
        this.setState({
          error:
            "* Houve um problema com o login, por favor verifique suas credenciais ou entre em contato com o Eventos do Grupo SEB."
        });
      }
    }
  };

  render() {

 
   



    return (
    <>
      <ExamplesNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_login.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <Container>
     
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
            

                <h3 className="title mx-auto" style={{textAlign:"center", fontWeight:600}}>Bem-vindo à<br /> SEB Store!</h3>
                <div className="social-line text-center">
                Por favor, faça login para acessar sua conta e trocar seus <i className="nc-icon nc-spaceship"></i> Tokens por produtos incríveis da loja.
                </div>               
             


                  
             

                  {JSON.parse(sessionStorage.getItem('user')) === null
                  ?
                  <Button block className="btn-round" color="danger"
                  onClick={() => {
                    const popup = window.open(
                      "https://sebstore.app.br/auth2/microsoft",
                      "targetWindow",
                      `toolbar=no,
                      status=no,
                      menubar=no,
                      scrollbars=no,
                      resizable=yes,
                      width=620,
                      height=700
                      `
                    );

                      window.addEventListener("message", (event) => {
                        if(event.origin === "https://sebstore.app.br"){
                          if (event.data) {
                            sessionStorage.setItem("user", JSON.stringify(event.data));
                            popup.close();
                            window.location.reload();
                          }
                        }
                      })

                  }}>
                    FAZER LOGIN
                  </Button>
                  : 
                  <div>
                  <Form name="handleSubmit" onSubmit={this.handleSubmit} >
                  <Button block className="btn-round"  type="submit" style={{backgroundColor:"#333"}}>
                  <i className="nc-icon nc-spaceship"></i> ENTRAR NA LOJA
                  </Button>
                  </Form>  
                  </div>
                  }
                 
              </Card>
            </Col>
          </Row>
         
        
        </Container>
       

        <div className="footer register-footer text-center">
          <h6>
            © {new Date().getFullYear()}, feito com{" "}
            <i className="fa fa-heart heart" /> pelo Grupo SEB.
          </h6>
        </div>
      </div>
    </>
  );
}}

export default withRouter(RegisterPage);
