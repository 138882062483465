
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import api from "../services/api.js";

import {
  Table,
} from "reactstrap";


class SendBoots extends Component {
 
  constructor(props) {
    super(props);  
    this.state = { 
      filter:[],     
    };
  }

   componentDidMount() {    
    this.loadBoots();
  }

  loadBoots = async () => {
    const response = await api.get(`/api/boots`);
    const docs = response.data;
    this.setState({ filter: docs});
   };


  render() {
  const { filter } = this.state;
  return (
    <>
         

    <Table striped>
      <thead>
        <tr>         
          <th>Data</th>
          <th>Colaborador</th>  
          <th>E-mail</th>  
          <th>Tipo</th> 
          <th>Tokens</th> 
        </tr>
      </thead>


      <tbody>

      { filter.map(tokens => (
          <tr key={tokens._id}>    
          <td> {moment(tokens.createdAt).format('DD/MM/YYYY')}</td>  
          {tokens.user === null
          ?
          <td></td>
          :
          <td>{tokens.user.nome}</td>            
        }   

{tokens.user === null
          ?
          <td></td>
          :
          <td>{tokens.user.email}</td>            
        }  

{tokens.user === null
          ?
          <td></td>
          :
          <td>{tokens.tipoboots.nome}</td>            
        }  
          
          <td>{tokens.boots}</td>

        
                
          </tr>
      ))}

        

        
      </tbody>
    </Table>


      

     
  

    </>
  );
}}

export default withRouter(SendBoots);
