import React, { useState, useEffect } from "react";
import JsPDF from 'jspdf';
import moment from 'moment';
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import CsvDownloader from 'react-csv-downloader';
import {
  Modal,
  ModalBody, 
  Table,
  Alert
} from "reactstrap";


function App() {
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false); 



  const toggle = () => setModal(!modal); 

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a1');
    report.html(document.querySelector('#report')).then(() => {
        report.save('reportSebStore.pdf');
    })};

  

  useEffect(() => {
 
    // axios.get('https://sebstore.app.br/api/troca')
    //     .then(res=>{

    //         const createdAt1 = res.data.createdAt;
    //         const produto1 = res.data.produto.nome;
    //         const unidade1 = res.data.unidade;
    //         const user1 = res.data.user.nome;
    //         const boots1 = res.data.boots;
    //         const status1 = res.data.status;

    //         setData({
    //           createdAt:createdAt1, 
    //           produto: produto1,
    //           unidade: unidade1,
    //           user: user1,
    //           boots: boots1,
    //           status: status1,
    //         })
    //         console.log(data);
    //     })
    //     .catch(err=>{
    //         console.log(err);
    //     })

    

      const trocasData = async () =>{
      const api = await fetch('https://sebstoreapi.app.br/api/troca')
      const trocasapi = await api.json();
      setData(trocasapi);
       console.log(trocasapi);
      
      
    };
    trocasData();
  }, []);



  const handleDateChange = (event, key) => {
    const newDate = new Date(event.target.value);
    setDateRange((prev) => ({ ...prev, [key]: newDate }));
  };

 

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.createdAt);
    return (!dateRange.start || itemDate >= dateRange.start ) && (!dateRange.end || itemDate <= dateRange.end);
  }).map((item2) => {
    return ({
      createdAt: item2.createdAt,
      produto: item2.produto.nome,
      unidade: item2.unidade.nome,
      user: item2.user.nome,
      email: item2.user.email,
      boots: item2.boots,
      status: item2.status,
    });
  });


  
  const filteredDataMap = data.map((item) => {
    return ({
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      produto: item.produto.nome,
      unidade: item.unidade.nome,
      user: item.user.nome,
      email: item.user.email,
      boots: item.boots,
      status: item.status,
    });
  });



  const columns = [{
    id: 'createdAt',
    displayName: 'Data'
  }, {
    id: 'produto',
    displayName: 'Produto'
  },
  {
    id: 'unidade',
    displayName: 'Unidade'
  },
  {
    id: 'user',
    displayName: 'Colaborador'
  },
  {
    id: 'email',
    displayName: 'E-mail'
  },
  {
    id: 'boots',
    displayName: 'Tokens'
  },
  {
    id: 'status',
    displayName: 'Status'
  }
];



  return (
    
    <div className='main'>
        <GerenciadorNavbar />

     
            <Modal isOpen={modal} 
                toggle={toggle}> 
                <ModalBody> 
                    Simple Modal with just ModalBody... 
                </ModalBody> 
            </Modal> 
  



        <div id="report" name="report" className="section section-buttons" style={{margin:40}}>
 
        <div style={{fontWeight:600, marginTop:0}}>
            <h1>Relatório de Pedidos</h1>
          </div>
<hr />
          <br />
      <label className='start-date-label' style={{fontWeight:"600"}}>Data Inicial </label>&nbsp;&nbsp;
      <input type="date" className='start-date-input' onChange={(e) => handleDateChange(e, 'start')} />
      &nbsp;&nbsp;&nbsp;
      <label className='end-date-label' style={{fontWeight:"600"}}>Data Final </label>&nbsp;&nbsp;
      <input type="date" className='end-date-input' onChange={(e) => handleDateChange(e, 'end')} />
      &nbsp;&nbsp;&nbsp;

      <CsvDownloader
       style={{fontWeight:"600"}}
        filename="PlanilhaSebStore"
        extension=".csv"
        separator=";"
        columns={columns}
        datas={filteredData}
        text="Exportar Planilha" />
&nbsp;&nbsp;&nbsp;
<button  style={{fontWeight:"600"}} onClick={generatePDF} type="button">Exportar PDF</button>




<br /><br />
      <Table striped>
      <thead>
        <tr>
         
          <th>Data</th>
          <th>Produto</th>  
          <th>Colaborador</th>  
          <th>E-mail</th> 
          <th>Unidade</th> 
          <th>Tokens</th>
          <th>Status</th>   
        </tr>
      </thead>
      <tbody>
        {filteredData.length === 0 ? (
          <p className='no-data-message'>Nenhum pedido encontrado.</p>
        ) : (
          filteredData.map((item3) => (
            <tr key={item3._id}>    
            <td> {moment(item3.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:400}}> 
       
           {item3.produto}
   
            
            </td>   
            <td style={{fontWeight:400}}> {item3.user}</td>  
            <td style={{fontWeight:400}}> {item3.email}</td>  
            <td style={{fontWeight:400}}> {item3.unidade}</td>  
            <td style={{fontWeight:400}}> {item3.boots}</td> 
            <td style={{fontWeight:400}}> 
            
            {(() => {
        switch (item3.status) {
          case '0':
            return <Alert color="danger" size="sm" style={{padding:6,  color:"#fff", textAlign:"center"}}> Cancelado </Alert>
          case '1':
            return <Alert color="warning" size="sm" style={{padding:6,  color:"#000", textAlign:"center"}}> Aguardando confirmação </Alert>
          case '2':
            return <Alert color="success" size="sm" style={{padding:6, color:"#fff", textAlign:"center"}}> Confirmado</Alert>
          case '3':
            return <Alert color="success" size="sm" style={{padding:6,  color:"#fff", textAlign:"center"}}> Enviado</Alert>
          case '4':
            return <Alert color="success" size="sm" style={{padding:6,  color:"#fff", textAlign:"center"}}> Entregue</Alert>
          default:
            return null
        }
      })()}
            
            
            </td>  


           

            </tr>
          ))
        )}
        </tbody>
      </Table>
   
      </div>
    </div>
  );
  }
  export default App;