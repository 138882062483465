import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiEdit2 } from "react-icons/fi";

import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";


class Perfil extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeSenha = this.handleChangeSenha.bind(this);
    this.state = {     
      modal: false,    
      senha:  props.senha,
    };

    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }



  async componentDidMount() {
  
    axios.get(`https://sebstore.app.br/api/user`)
    .then((response) => {
      const user = response.data;
      const filter = response.data;
      this.setState({
        user,
        filter
      })
    })
      
 
   
            
  }




  handleChangeSenha = (event) => {
    event.persist();
    this.setState({ senha: event.target.value });
  }




    handleUpdate = event => {
      event.preventDefault();  
   
        const user = JSON.parse(localStorage.getItem('user'));
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };

      axios.put(`https://sebstore.app.br/api/user/${user._id}`, {
        id_unidade: user.id_unidade,
        tipo: user.tipo,
        nome: user.nome,
        email: user.email,
        senha: this.state.senha,
        endereco: user.endereco,
        complemento: user.complemento,
        bairro: user.bairro,
        cidade: user.cidade,
        estado: user.estado,
        cep: user.cep,
        status: user.status   
        }, config)
          .then(res => {
            alert('Usuário alterado com sucesso!');
            this.props.history.push("/perfil");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      

      }

      


    renderUser() {
      const user = JSON.parse(localStorage.getItem('user'));
      return (
        <tr>    
        <td> {moment(user.createdAt).format('DD/MM/YYYY')}</td>  
        <td style={{fontWeight:400}}> {user.nome}</td>
        <td style={{fontWeight:200}}> {user.email}</td>       

      
        <td>
          
        <Form name="handleUpdate" onSubmit={this.handleUpdate}>

<FormGroup>
<label htmlFor="senha"><span style={{fontWeight:600}}>Nova Senha</span></label>
 <Input           
 name="senha"
 id="senha"
 type="password"
 required="required"
 onChange={this.handleChangeSenha}

  /><Button size="sm" color="success" type="submit">Salvar</Button>
</FormGroup>
</Form>
          
          </td>   
      
        </tr>
      );
  }
  




  render() {

    const { modal2, user } = this.state;


   

  return (
    <>
      <IndexNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Perfil</h1>
          </div>

  




<br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
         <ModalBody>
       
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
 
        </Modal>


        

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
           <th>Usuário</th>
          <th>E-mail</th>  
          <th>&nbsp;</th>   
       
        </tr>
      </thead>


      <tbody>
   {this.renderUser()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     
   
        </div>
      </div>
    </>
  );
}}

export default withRouter(Perfil);
