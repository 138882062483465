import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
//import {history} from './history';

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import Produto from "views/Produto.js";
import MinhasCompras from "views/MinhasCompras";
import RecuperarSenha from "views/examples/RecuperarSenha.js";
import Cadastro from "views/examples/Cadastro.js";
import RegisterPage from "views/examples/RegisterPage.js";
import Busca from "views/Busca";
import Boots from "views/Boots";
import Gerenciador from "views/Gerenciador";
import Sair from "views/Sair.js";
import Categoria from "views/Categoria.js";
import ProfilePage from "views/Perfil";
import TrocasAdmin from "views/TrocasAdmin";
import ProdutosAdmin from "views/ProdutosAdmin";
import CategoriasAdmin from "views/CategoriasAdmin";
import TiposBootsAdmin from "views/TiposBootsAdmin";
import BootsAdmin from "views/BootsAdmin";
import UnidadeNegocioAdmin from "views/UnidadeNegocioAdmin";
import UnidadeAdmin from "views/UnidadeAdmin";
import UserAdmin from "views/UserAdmin";
import SendBoots from "views/SendBoots";
import SendBoots2 from "views/SendBoots2";
import CategoriaAdmin from "views/CategoriaAdmin"
import TrocaSelect from "views/TrocaSelect";
import FiltroData from "views/FiltroData";
import VouchersAdmin from "views/VouchersAdmin";
import Vouchers from "views/Vouchers";
import { isAuthenticated } from "services/auth.js";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    
    render={props =>
      
      isAuthenticated()     
      
      ? (
        <Component {...props} />
      ) : 
      
      (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
// others

ReactDOM.render(
  <Router>
    <Switch>
    <Route exact path="/" component={RegisterPage} />
    <Route path="/cadastro" component={Cadastro} />  
    <Route path="/recuperarsenha" component={RecuperarSenha} /> 
    <Route path="/teste" component={SendBoots2} />
    <PrivateRoute path="/index" component={Index} />  
    <PrivateRoute path="/categoria/:id" component={Categoria} />  
    <PrivateRoute path="/produto/:id" component={Produto} />
    <PrivateRoute path="/minhas-trocas" component={MinhasCompras} />
    <PrivateRoute path="/perfil" component={ProfilePage} />
    <PrivateRoute path="/busca" component={Busca} />
    <PrivateRoute path="/boots" component={Boots} />
    <PrivateRoute path="/gerenciador" component={Gerenciador} />
    <PrivateRoute path="/trocas-admin" component={TrocasAdmin} />
    <PrivateRoute path="/categorias-admin" component={CategoriasAdmin} />
    <PrivateRoute path="/produtos-admin" component={ProdutosAdmin} />
    <PrivateRoute path="/tipos-boots-admin" component={TiposBootsAdmin} />
    <PrivateRoute path="/boots-admin" component={BootsAdmin} /> 
    <PrivateRoute path="/unidade-negocio-admin" component={UnidadeNegocioAdmin} />    
    <PrivateRoute path="/unidade-admin" component={UnidadeAdmin} />
    <PrivateRoute path="/user-admin" component={UserAdmin} />
    <PrivateRoute path="/send-boots" component={SendBoots} />    
    <PrivateRoute path="/categoria-admin" component={CategoriaAdmin} />
    <PrivateRoute path="/voucher-admin" component={VouchersAdmin} />
    <PrivateRoute path="/troca-select/:id" component={TrocaSelect} />
    <PrivateRoute path="/filtro" component={FiltroData} />
    <PrivateRoute path="/vouchers-s" component={Vouchers} />
    
    <PrivateRoute path="/sair" component={Sair} />        
    <Route path="*" component={() => <h1>Página não encontrada!</h1>} />  
    </Switch>
  </Router>,
  document.getElementById("root")
);
