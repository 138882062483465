import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// plugin that creates slider
import Slider from "nouislider";
import axios from 'axios';
import TotalBoots from "../../components/Headers/TotalBoots.js";

// reactstrap components
import {
  Button,
  Collapse,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Label,
  Alert
} from "reactstrap";



class SectionTrocaSelect extends Component {

  constructor(props) {    
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
     this.state = {
        produto : [],
        categoria:{},
        boots: [],
        unidade:{},
        unidades:[],
        unidades2:[],
        user:[],
        trocas:[],
        trocaselect:[],
        total:"0",
        total2:"0",        
       };    
           
  }


  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
 
  async componentDidMount(){  
    const userid = JSON.parse(localStorage.getItem('user'));
    axios.get(`https://sebstoreapi.app.br/api/user/${userid._id}`)
    .then(res => {
      const user = res.data;
      this.setState({ user});
    })

    axios.get(`https://sebstoreapi.app.br/api/boots-user/${userid._id}`)
    .then((response) => {
      const boots = response.data;
      this.setState({
        boots,        
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
       
    })

    axios.get(`https://sebstoreapi.app.br/api/troca-user/${userid._id}`)
    .then((response) => {
      const trocas = response.data;
      this.setState({
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
      console.log( trocas );
   
    })
    
    const { id } = this.props.match.params;       
    axios.get(`https://sebstoreapi.app.br/api/troca/${id}`)
    .then(res => {
      const trocaselect = res.data;
      const produto = res.data.produto;
      const unidades = res.data.unidade;
      this.setState({ trocaselect, produto, unidades});
 

      axios.get(`https://sebstoreapi.app.br/api/unidade/${this.state.unidades}`)
    .then((response) => {
      const unidades2 = response.data;
      this.setState({
        unidades2

      });
      console.log(this.state.unidades2.nome);
       
    })

    })

    
  };

  
  render() {

    const { produto, categoria, user, unidade, unidades, unidades2, total, total2, trocaselect } = this.state; 

    const bootstotal = total - total2;
    


  return (
    <>
      <div className="section section-buttons">
        <Container>
        <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> <a href={"/minhas-trocas"} style={{fontWeight:600,}}> | Minhas Trocas</a> | {produto.nome}
          </div>
          <div style={{fontWeight:600,}}>
            <h1>{produto.nome}</h1>
          </div>

          <hr />
        <Row style={{marginTop:0}}>
              <Col sm>
              <Card style={{width:"80%"}}>
              <CardBody>
                   

<img src={(`https://sebstoreapi.app.br/uploads/${produto.img}`)} style={{width:"100%"}} />

                </CardBody>
              </Card>
              </Col>


              <Col sm>
              <div style={{paddingLeft:0, marginTop:20, marginBottom:0, fontSize:18,  paddingRight:0, fontWeight:400,}}>  
              {produto.desc}
              </div>

<hr />
<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  
<Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:18, color:"#F5593D"}}>Local de entrega:</span> <br /><br />{unidades.nome}
                  <br />

                  <a onClick={this.toggle}  style={{cursor:"pointer", fontWeight:600}}><u><br />Ver o Endereço</u></a>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
            <FormGroup>
          <Label for="endereco"><span style={{fontWeight:600}}>Endereço</span></Label><br />
          {unidades.endereco}
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
          <Label for="complemento"><span style={{fontWeight:600}}>Complemento</span></Label><br />
          {unidades.complemento}
          </FormGroup>
          <FormGroup className="col-md-6">
          <Label for="bairro"><span style={{fontWeight:600}}>Bairro</span></Label><br />
          {unidades.bairro}
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="cidade"><span style={{fontWeight:600}}>Cidade</span></Label><br />
            {unidades.cidade}
          </FormGroup>
          <FormGroup className="col-md-4">
            <Label for="estado"><span style={{fontWeight:600}}>Estado</span></Label><br />
            {unidades.estado}
          </FormGroup>
          <FormGroup className="col-md-2">
            <Label for="cep"><span style={{fontWeight:600}}>CEP</span></Label><br />
            {unidades.cep}
         
          </FormGroup>
          </div>
            </CardBody>
          </Card>
        </Collapse>
                  
                  </Col>          
                </Row>
             
</div>


     


<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  
                <Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:32, color:"#F5593D"}}>{produto.boots} Tokens</span>
                  </Col>
                  <Col style={{marginTop:10}}>

                
    



{(() => {
        switch (trocaselect.status) {
          case '0':
            return <Alert color="danger" size="sm" style={{padding:6, textAlign:"center"}}> Cancelado </Alert>
          case '1':
            return <Alert color="warning" size="sm" style={{padding:6, textAlign:"center", color:"#000"}}> Aguardando confirmação </Alert>
          case '2':
            return <Alert color="success" size="sm" style={{padding:6, textAlign:"center"}}> Confirmado</Alert>
          case '3':
            return <Alert color="success" size="sm" style={{padding:6, textAlign:"center"}}> Enviado</Alert>
          case '4':
            return <Alert color="success" size="sm" style={{padding:6, textAlign:"center"}}> Entregue</Alert>
          default:
            return null
        }
      })()}




                 
                  </Col>
                </Row>
                </div>

      
              </Col>
              
            
            </Row>



            <Modal isOpen={this.state.modal} toggle={this.toggle2} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>{produto.nome}  </span></ModalHeader>
          <ModalBody>
            <h3>
            Confirme seu pedido de troca de Boot's.
            </h3>
            <br /><br />
            
                    
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Efetuar troca</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle2}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>
                
        </Container>
      </div>
    </>
  );
}

}

export default withRouter(SectionTrocaSelect);
