import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import axios from 'axios';
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// reactstrap components
import {
  Button,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input
} from "reactstrap";



class Produto extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.state = { collapse: false };
    this.state = {
        produto : [],
        categoria:{},
        img:null,
        boots: [],
        unidade:{},
        user:[],
        trocas:[],
        total:"0",
        total2:"0",
        unidades: [],
        user2: props.user2,
        produto2: props.produto2,
        boots2: props.boots2,
        status2: props.status2,
        selectedPost: null,
       };   
       
         
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

 
  toggle2() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeUnidade = (event) => {
    event.persist();
    this.setState({ unidade: event.target.value });
  }
  

  async componentDidMount(){  
    const userid = JSON.parse(localStorage.getItem('user'));
   
    axios.get(`https://sebstoreapi.app.br/api/user/${userid._id}`)
    .then(res => {
      const user = res.data;
      const unidade = res.data.id_unidade;
      this.setState({ user, unidade});
      console.log(unidade);
    })

    axios.get(`https://sebstoreapi.app.br/api/boots-user/${userid._id}`)
    .then((response) => {
      const boots = response.data;
      this.setState({
        boots,        
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
       
    })

    axios.get(`https://sebstoreapi.app.br/api/troca-user/${userid._id}`)
    .then((response) => {
      const trocas = response.data;
      this.setState({
        trocas,
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
     
    })
    
    const { id } = this.props.match.params;       
    axios.get(`https://sebstoreapi.app.br/api/produtos/${id}`)
    .then(res => {
      const produto = res.data;
      const categoria = res.data.categoria;
      this.setState({ produto, categoria});
      console.log(this.state.produto.img)
    })

    axios.get(`https://sebstoreapi.app.br/api/unidade`)
    .then(res => {
      const unidades = res.data;
      this.setState({ unidades });
    })

    
  };


  



  handleSubmit = event => {
  
    event.preventDefault();   
    const userid = JSON.parse(localStorage.getItem('user'));
    console.log(userid._id);
    axios.post(`https://sebstoreapi.app.br/api/troca`, { 
      user: userid._id,
      produto: this.state.produto._id,
      unidade: this.state.unidade,
      boots: this.state.produto.boots, 
      status: "1"
    })
    .then(res => {
      alert('Troca efetuada com sucesso! Em alguns minutos seu pedido de troca estará disponível. Aguarde...');
      this.props.history.push("/minhas-trocas");
      window.location.reload();
    })
    .catch(function (error) {
      alert('Ocorreu algum erro!');
      console.log(error);
     });

     const config = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
    };

      
  axios.put(`https://sebstoreapi.app.br/api/produto/${this.state.produto._id}`, {
    estoque: parseInt(this.state.produto.estoque) - 1, 
    }, config)
      .then(res => {
        //alert('Produto editado com sucesso!');   
      })
      .catch(function (error) {
        alert('Ocorreu algum erro!');
        console.log(error);
    });

    
  
  
    }



  render() {

    const { produto, categoria, unidade, unidades, total, total2, img} = this.state; 

    const bootstotal = total - total2;

    const baseUrl = "../../../public/uploads/";
    


  return (
    <>
    <IndexNavbar />
      <div className="section section-buttons">
        <Container>
        <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> <a href={`/categoria/${categoria._id}`} style={{fontWeight:600,}}> | {categoria.nome}</a> | {produto.nome}
          </div>
          <div style={{fontWeight:600,}}>
            <h1>{produto.nome}</h1>
          </div>

          <hr />
        <Row style={{marginTop:0}}>
              <Col sm>
              <Card style={{width:"80%"}}>
              <CardBody>

          
              <img src={(`https://sebstoreapi.app.br/uploads/${produto.img}`)} style={{width:"100%"}} /><br /><hr />


                      
            <img src={(`https://sebstoreapi.app.br/uploads/${produto.img2}`)} style={{width:"100%"}} />
           

             
     

                </CardBody>
              </Card>
              </Col>


              <Col sm>
              <div style={{paddingLeft:0, marginTop:20, marginBottom:0, fontSize:18,  paddingRight:0, fontWeight:400,}}>  
              {produto.desc}
              </div>

<hr />
<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  

<Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:18, color:"#F5593D"}}>Estoque do produto:</span>  {produto.estoque} unidades.
                  </Col>          
                </Row>
</div>

<hr />





     


<div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  
                <Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:30, color:"#F5593D"}}> {produto.boots} Tokens</span>
                  </Col>
                  <Col style={{marginTop:10}}>

                  {parseInt(produto.estoque) <= 0
        ?  <Button
        className="btn-round"
        color="secundary"
        style={{width:"100%"}} 
        disabled="disabled"
        >
        Produto esgotado
          </Button>
            :  
              <>
              {parseInt(bootstotal) < parseInt(produto.boots)
        ?  <Button
        className="btn-round"
        color="secundary"
        style={{width:"100%"}} 
        disabled="disabled"
        >
        Tokens insuficientes
          </Button>
            :  <Button
            className="btn-round"
            color="danger"
            style={{width:"100%"}} 
            onClick={this.toggle2}
            >
        Trocar Tokens
          </Button>
          }
              </>
            
          }





                 
                  </Col>
                </Row>
                </div>

      
              </Col>
              
            
            </Row>



            <Modal isOpen={this.state.modal} toggle={this.toggle2} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>{produto.nome}  </span></ModalHeader>
          <ModalBody>
          
            <div style={{paddingLeft:0, marginTop:20, marginBottom:0, paddingRight:0, fontWeight:400,}}>  
<Row>
                  <Col>
                  <span style={{fontWeight:600, fontSize:18, color:"#F5593D"}}>Selecione o local de entrega:</span> 
<br />&nbsp;
             
<Input type="select" name="unidade" id="unidade" onChange={this.handleChangeUnidade}>
        <option>Selecione...</option>
        {this.state.unidades.map(tipo => (
             tipo.status === "3" 
             ?
             <option style={{display:"none"}} key={tipo._id} value={tipo._id}>{tipo.nome}</option>
             : 
             <option key={tipo._id} value={tipo._id}>{tipo.nome}</option>
             ))} 
        </Input>
        <br />
                  
                  </Col>          
                </Row>
               
</div>

            
                    
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Efetuar troca</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle2}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>
                
        </Container>

        <DemoFooter />
      </div>
    </>
  );
}

}

export default withRouter(Produto);
