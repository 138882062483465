import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// plugin that creates slider
import Slider from "nouislider";
import axios from 'axios';

import api from "../../services/api.js";



// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CustomInput,
  Card,
} from "reactstrap";


class Categorias extends Component {

  constructor(props) {
    super(props);
    console.log(this.props.match.params.id)
    this.state = {
        produtos : [],
        categoria: [],
        };

        
  }

  componentDidMount(){        
    this.loadProdutos();
    this.loadCategoria();
  };

  loadProdutos = async () => {
    const { id } = this.props.match.params;   
    console.log(id); 
    const response = await api.get(`/api/produto-categoria/${id}`);
    this.setState({
      produtos: response.data,   
    });
    
  };

  loadCategoria = async () => {
    const { id } = this.props.match.params;   
    console.log(id); 
    const response = await api.get(`/api/categoria/${id}`);
    this.setState({
      categoria: response.data,   
    });
    
  };
    

  render() {

    const { produtos, categoria } = this.state; 
    

  return (
 
    <>
      <div className="section section-buttons">
        <Container>
        <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> | {categoria.nome}
          </div>
          <div style={{fontWeight:600,}}>
            <h1>{categoria.nome}</h1>

       

          </div>

       <hr />

       <Row>
            
            {produtos.map(produto => (
           
           produto.status === "3" 
           ?
           <Col sm style={{display:"none"}}></Col>
           :  
           <Col sm>
                   <Card style={{width: '15rem'}}>
                     <div style={{padding:15, fontWeight:400,}}>
                     <img src={(`https://sebstoreapi.app.br/uploads/${produto.img}`)} style={{height:150, width:"100%"}} />
                     </div>
                     <div style={{paddingLeft:15, paddingRight:15, fontWeight:600,}}>         
                     {produto.nome}
                     </div>
                     <div style={{paddingLeft:15, paddingRight:15, fontWeight:400, whiteSpace:"nowrap", width:"18em", overflow:"hidden", textOverflow:"ellipsis"}}>         
                     {produto.desc}
                     </div>
                     <div style={{paddingLeft:15, marginTop:15, marginBottom:15, paddingRight:15, fontWeight:400,}}>  
                     <Row>
                       <Col>
                       <span style={{fontWeight:600, fontSize:22, color:"#F5593D"}}>{produto.boots} <i className="nc-icon nc-spaceship"></i></span>
                       </Col>
                       <Col>
                       <Button
                     className="btn-round"
                     color="danger"
                     href={`/produto/${produto._id}`}
                     style={{width:"100%"}} 
                     >
                 Trocar
                   </Button>
                       </Col>
                     </Row>
                     </div>
                   </Card>
                   </Col>
     
          ))} 
          </Row>

          
        
          
        </Container>
      </div>
    </>
  );
}}

export default withRouter(Categorias);
