
import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";


// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "reactstrap";


class TiposBootsAdmin extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.state = {
      tipoboots: [],
      filter:[],
      modal: false,
      modal2: false,
      modal3: false,
      nome: props.nome,
      desc: props.desc,    
      status: props.status,
      selectedPost: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  toggle3 = i => {
    this.setState({
      modal3: !this.state.modal3,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };



  componentDidMount() {
    axios.get(`https://sebstoreapi.app.br/api/tipo-boots`)
      .then(res => {
        const tipoboots = res.data;
        const filter = res.data;
        this.setState({ tipoboots, filter });
      })
  }


  handleChangeNome = event => {
    this.setState({ nome: event.target.value });
  }


  handleChangeDesc = event => {
    this.setState({ desc: event.target.value });
  }


  handleChangeStatus = event => {
    this.setState({ status: event.target.value });
  }



  handleSubmit = event => {
    event.preventDefault(); 
    axios.post(`https://sebstoreapi.app.br/api/tipo-boots`, {
    nome: this.state.nome,
    desc: this.state.desc,
    status: this.state.status
    })
    .then(res => {
      alert('Tipo criado com sucesso!');
      this.props.history.push("/tipos-boots-admin");
      window.location.reload();
    })
    .catch(function (error) {
      alert('Ocorreu algum erro!');
      console.log(error);
     });
    }

    handleUpdate = event => {
      event.preventDefault();  

      if (this.state.selectedPost !== null) {
        const tipoboots = this.state.tipoboots[this.state.selectedPost];
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://sebstoreapi.app.br/api/tipo-boots/${tipoboots._id}`, {
        nome: this.state.nome,
        desc: this.state.desc,
        status:this.state.status
      }, config)
          .then(res => {
            alert('Tipo alterado com sucesso!');
            this.props.history.push("/tipos-boots-admin");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }

      }


      handleDelete = event => {
        event.preventDefault();  

        if (this.state.selectedPost !== null) {
          const status2 = "3";
          const tipoboots = this.state.tipoboots[this.state.selectedPost];
          const config = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
          }
          };
        
        axios.put(`https://sebstoreapi.app.br/api/tipo-boots/${tipoboots._id}`, {         
          status:status2
        }, config)
            .then(res => {
              alert('Tipo excluído com sucesso!');
              this.props.history.push("/tipos-boots-admin");
              window.location.reload();
            })
            .catch(function (error) {
              alert('Ocorreu algum erro!');
              console.log(error);
          });
        }};

      


    renderUnidades() {
      return this.state.filter.map((tipos, i) => {
          return (
            tipos.status === "3" 
            ?
            <tr style={{display:"none"}}></tr>
            :
            <tr key={tipos._id}>    
            <td> {moment(tipos.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:400}}> {tipos.nome}</td> 
            <td style={{fontWeight:200}}> {tipos.desc}</td> 
            <td>
              
            {tipos.status === "1"
            ?
            <Alert color="success" style={{padding:6, textAlign:"center"}}>
            Ativo
            </Alert>
            : 
            <Alert color="danger" style={{padding:6, textAlign:"center"}}>
            Inativo
            </Alert>
            }
            </td>  
            <td><Button
                    size="sm"
                    color="info"
                    onClick={() => this.toggle2(i)}
                    
                    >
                <FiEdit2 />
                  </Button></td> 
                  <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => this.toggle3(i)}
                    >
                <FiX />
                  </Button>
                  </td>   
                 
            </tr>
          );
      });
  }
  
  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const tipoboots = this.state.tipoboots[this.state.selectedPost];
      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Editar Tipo de Tokens</span></ModalHeader>
          <ModalBody>
          Utilize o formulário para editar o Tipo de Tokens e clique em salvar ou cancelar.
            <br /><br />
            
                  <label htmlFor="nome"><span style={{fontWeight:600}}>Tipo</span></label>
                  <Input 
                  placeholder={tipoboots.nome}
                  defaultValue={tipoboots.nome}
                  value={this.state.nome}
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChangeNome}              
                   /> 

                   <br />

                   <label htmlFor="desc"><span style={{fontWeight:600}}>Descrição</span></label>
                  <Input 
                  placeholder={tipoboots.desc}
                  defaultValue={tipoboots.desc}
                  value={this.state.desc}
                  name="desc"
                  id="desc"
                  type="textarea"
                  required="required"
                  onChange={this.handleChangeDesc}              
                   /> 

<br />  <label htmlFor="status"><span style={{fontWeight:600}}>Status</span></label>
        <Input type="select" name="status" id="status" onChange={this.handleChangeStatus}>
        <option value="1">Selecione...</option>           
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>              
        </Input>

            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" href="/tipos-boots-admin">Cancelar</Button>
          </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  renderModal2 = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const tipoboots = this.state.tipoboots[this.state.selectedPost];
      return (
      
          <Form name="handleDelete" onSubmit={this.handleDelete}>
          <ModalHeader><span style={{fontWeight:600}}>Excluir Tipo de Coins</span></ModalHeader>
          <ModalBody>
          Confirme a exclusão do Tipo de Tokens clicando em excluir ou cancelar.
            <br /><br />
            
           <label htmlFor="nome"><span style={{fontWeight:600}}>{tipoboots.nome}</span></label>             
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="danger" type="submit">Excluir</Button>{' '}
            <Button outline size="sm" color="danger" href="/tipos-boots-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  render() {

    const { modal2, tipoboots } = this.state;


    const requestSearch = (searchedVal) => {
      const filteredRows = tipoboots.filter((row) => {
          return row.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      });
      if (searchedVal.length < 1) {
          this.setState({filter: tipoboots});
      }
      else {
        this.setState({filter: filteredRows});
      }
    };

  return (
    <>
      <GerenciadorNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Tipos de Tokens</h1>
          </div>

          <Row>
            <Col sm style={{textAlign:"right"}}>
 <Button
                size="sm"
                color="success"
                onClick={this.toggle}
                >
            novo tipo
              </Button>       
           
            </Col>
          </Row>

          <br />

          <form>
<Row style={{marginTop:0}}>
<Col>    


<InputGroup>
<InputGroupAddon addonType="prepend">
<InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
</InputGroupAddon>
<Input type="search" placeholder="Digite o título do tipo de Tokens..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
</InputGroup>



</Col>                   
</Row>
</form>

<br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Novo Tipo de Tokens</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com os dados no novo tipo de Tokens e clique em salvar ou cancelar.
            <br /><br />
            
                  <label htmlFor="nome"><span style={{fontWeight:600}}>Tipo de Tokens</span></label>
                  <Input 
                  placeholder="Digite um nome..."
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChangeNome}
                   />

                   <br />

                   <label htmlFor="desc"><span style={{fontWeight:600}}>Descrição</span></label>
                  <Input 
                  placeholder="Digite uma descrição..."
                  name="desc"
                  id="desc"
                  type="textarea"
                  required="required"
                  onChange={this.handleChangeDesc}
                   />

<br />
          <label htmlFor="status"><span style={{fontWeight:600}}>Status</span></label>
        <Input type="select" name="status" id="status" onChange={this.handleChangeStatus}>
        <option value="1">Selecione...</option>           
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>              
        </Input>
      

                        
          </ModalBody>
          <ModalFooter>
            <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>


        <Modal isOpen={this.state.modal2} className={this.props.className}>
        {this.renderModal(this.state.tipoboots[this.state.selectedPost])}
        </Modal>

        <Modal isOpen={this.state.modal3} className={this.props.className}>
        {this.renderModal2(this.state.tipoboots[this.state.selectedPost])}
        </Modal>


        

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th width="30%">Tipo de Tokens</th>   
          <th width="50%">Descrição</th> 
          <th width="10%">Status</th>   
          <th width="5%">&nbsp;</th>   
          <th width="5%">&nbsp;</th>   
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     
        <DemoFooter />
        </div>
      </div>
    </>
  );
}}

export default withRouter(TiposBootsAdmin);
