import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import api from "../services/api.js";

import FileUpload from "./FileUpload";



// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Alert,
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";


class ProdutosAdmin extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.handleChangeBoots = this.handleChangeBoots.bind(this);
    this.handleChangeEstoque = this.handleChangeEstoque.bind(this);
    this.handleChangeImg = this.handleChangeImg.bind(this);    
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.state = {     
      produtos: [],
      categorias: [],
      filter:[],
      modal: false,
      modal2: false,
      modal3: false,
      categoria:  props.categoria,
      nome: props.nome,
      desc: props.desc,
      boots: props.boots,
      estoque: props.estoque,
      img: props.img,
      img2: props.img2,
      img3: props.img3,
      img4: props.img4,
      status: props.status,
      selectedPost: null,
      fileData:[],
      fileData2:[],
      fileData3:[],
      fileData4:[],
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  toggle3 = i => {
    this.setState({
      modal3: !this.state.modal3,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  async componentDidMount() {
    axios.get(`https://sebstoreapi.app.br/api/produto`)
    .then((response) => {
      const produtos = response.data;
      const filter = response.data;
      this.setState({
        produtos, filter
      })
    })
      
    axios.get(`https://sebstoreapi.app.br/api/categoria`)
    .then(res => {
      const categorias = res.data;
      this.setState({ categorias });
    })            
  }

  handleChangefileData = (event) => {
    event.persist();
    this.setState({fileData: event.target.files[0] });
  }

  handleChangefileData2 = (event) => {
    event.persist();
    this.setState({fileData2: event.target.files[0] });
  }

  handleChangeCategoria = (event) => {
    event.persist();
    this.setState({categoria: event.target.value });
  }
 
  handleChangeNome = (event) => {
    event.persist();
    this.setState({ nome: event.target.value });
  }

  handleChangeDesc = (event) => {
    event.persist();
    this.setState({ desc: event.target.value });
  }

  handleChangeBoots = (event) => {
    event.persist();
    this.setState({ boots: event.target.value });
  }

  handleChangeEstoque = (event) => {
    event.persist();
    this.setState({ estoque: event.target.value });
  }

  handleChangeImg = (event) => {
    event.persist();
    this.setState({ img: event.target.value });
  }

  handleChangeStatus = (event) => {
    event.persist();
    this.setState({ status: event.target.value });
  }
 
 

  handleSubmit = event => {
    event.preventDefault(); 
    const imagem2 = "2";
    const imagem3 = "0";
    const imagem4 = "0";
    const data = new FormData();
    data.append("file", this.state.fileData);    
    axios({
    method: "POST",
    url: "https://sebstoreapi.app.br/upload",
    data: data,
    }).then((res) => {   
      
      const data2 = new FormData();
      data2.append("file", this.state.fileData2);    
      axios({
      method: "POST",
      url: "https://sebstoreapi.app.br/upload",
      data: data2,
      }).then((res) => {     
        //alert(Date.now() + '-' +this.state.fileData.name);
        axios.post(`https://sebstoreapi.app.br/api/produto`, { 
          categoria: this.state.categoria,
          nome: this.state.nome,
          desc: this.state.desc,
          boots: this.state.boots,
          estoque: this.state.estoque,
          img: this.state.fileData.name,
          img2: this.state.fileData2.name,
          img3: imagem3,
          img4: imagem4,
          status: this.state.status    
        })
        .then(res => {
          alert('Produto criado com sucesso!');
          this.props.history.push("/produtos-admin");
          window.location.reload();
        })
        .catch(function (error) {
          alert('Ocorreu algum erro!');
          console.log(error);
         });
    });
  });

   
    }

    handleUpdate = event => {
      event.preventDefault();  
      if (this.state.selectedPost !== null) {
        const produto = this.state.produtos[this.state.selectedPost];

      const data = new FormData();
      data.append("file", this.state.fileData);    
      axios({
      method: "POST",
      url: "https://sebstoreapi.app.br/upload",
      data: data,
      }).then((res) => {  


        const data2 = new FormData();
      data2.append("file", this.state.fileData2);    
      axios({
      method: "POST",
      url: "https://sebstoreapi.app.br/upload",
      data: data2,
      }).then((res) => {  

        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
        
        axios.put(`https://sebstoreapi.app.br/api/produto/${produto._id}`, {
          categoria: this.state.categoria,
          nome: this.state.nome,
          desc: this.state.desc,
          boots: this.state.boots,
          estoque: this.state.estoque,
          img: this.state.fileData.name,
          img2: this.state.fileData2.name,
          status: this.state.status      
          }, config)
            .then(res => {
              alert('Produto editado com sucesso!');
              this.props.history.push("/produtos-admin");
              window.location.reload();
            })
            .catch(function (error) {
              alert('Ocorreu algum erro!');
              console.log(error);
          });
        

      })})}; 

     

      }


      handleDelete = event => {
        event.preventDefault();  
      if (this.state.selectedPost !== null) {
        const produto = this.state.produtos[this.state.selectedPost];
        const status2 = "3";     

        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
        
        axios.put(`https://sebstoreapi.app.br/api/produto/${produto._id}`, {
           status: status2    
          }, config)
            .then(res => {
              alert('Produto excluído com sucesso!');
              this.props.history.push("/produtos-admin");
              window.location.reload();
            })
            .catch(function (error) {
              alert('Ocorreu algum erro!');
              console.log(error);
         
        

      })}; };

      


    renderUnidades() {
      return this.state.filter.map((produto, i) => {

         
          return (
            produto.status === "3" 
            ?
            <tr style={{display:"none"}}></tr>
            :
            <tr key={produto._id}>    
            <td> {moment(produto.createdAt).format('DD/MM/YYYY')}</td>     
            <td style={{fontWeight:200}}> {produto.categoria.nome}</td>  
            <td style={{fontWeight:400}}> {produto.nome}</td>  
            <td style={{fontWeight:200}}> {produto.desc}</td>  
            <td style={{fontWeight:400}}> {produto.boots}</td>    
            <td style={{fontWeight:400}}> {produto.estoque}</td>   
            <td style={{fontWeight:400}}> 
            
            {produto.status === "1"
             ?
             <Alert color="success" size="sm" style={{padding:6, textAlign:"center"}}>
             Publicado
             </Alert>
             : 
             <Alert color="primary" size="sm" style={{padding:6, textAlign:"center"}}>
             Rascunho
             </Alert>
             }
            </td>  
            <td><Button
                    size="sm"
                    color="info"
                    onClick={() => this.toggle2(i)}
                    
                    >
 



                <FiEdit2 />
                  </Button></td>   


                  <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => this.toggle3(i)}
                    >
                <FiX />
                  </Button>
                  </td>
               
            </tr>
          );
      });
  }
  
  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const produto = this.state.produtos[this.state.selectedPost];
      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Editar Produto</span></ModalHeader>
          <ModalBody>
          Utilize o formulário para editar informações do produto e clique em salvar ou cancelar.
            <br /><br />
            
                 




<label htmlFor="categoria"><span style={{fontWeight:600}}>Categoria / {produto.categoria.nome}</span></label>
        <Input type="select" name="categoria" id="categoria" onChange={this.handleChangeCategoria}>
        <option value={produto.categoria}>Selecione...</option>

            {this.state.categorias.map(categoria => (
              categoria.status === "3" 
              ?
              <option style={{display:"none"}} key={categoria._id} value={categoria._id}>{categoria.nome}</option>
              : 
              <option key={categoria._id} value={categoria._id}>{categoria.nome}</option>
             ))}                  
                
        </Input>
        <br />


        <label htmlFor="nome"><span style={{fontWeight:600}}>Produto</span></label>
                  <Input
                  placeholder={produto.nome}
                  defaultValue={produto.nome}
                  value={this.state.nome}
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChangeNome}
                   />

                   <br />

       
        <FormGroup>
          <Label for="desc"><span style={{fontWeight:600}}>Descrição</span></Label>
          <Input 
          type="textarea" 
          id="desc" 
          required="desc" 
          onChange={this.handleChangeDesc} 
          placeholder={produto.desc}
          defaultValue={produto.desc}
          value={this.state.desc} 
          />
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
          <Label for="boots"><span style={{fontWeight:600}}>Tokens</span></Label>
          <Input 
          type="text" 
          id="boots" 
          name="boots"
          onChange={this.handleChangeBoots} 
          placeholder={produto.boots}
          defaultValue={produto.boots}
          value={this.state.boots} 
          />
          </FormGroup>
          <FormGroup className="col-md-6">
          <Label for="estoque"><span style={{fontWeight:600}}>Estoque</span></Label>
          <Input 
          type="text" 
          id="estoque" 
          name="estoque"
          required="required" 
          onChange={this.handleChangeEstoque} 
          placeholder={produto.estoque}
          defaultValue={produto.estoque}
          value={this.state.estoque} 
          />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="img"><span style={{fontWeight:600}}>Imagem</span></Label><br />
        
            <img src={(`https://sebstoreapi.app.br/uploads/${produto.img}`)} style={{height:150, width:"100%"}} />
          <br /><br />
            <input type="file" name="img" id="img" onChange={this.handleChangefileData}  value={this.state.img} defaultValue={this.state.img}  />


          </FormGroup>
          <FormGroup className="col-md-6">
            
            <Label for="img"><span style={{fontWeight:600}}>Imagem2</span></Label><br />
        
            <img src={(`https://sebstoreapi.app.br/uploads/${produto.img2}`)} style={{height:150, width:"100%"}} />
          <br /><br />
            <input type="file" name="img2" id="img2" onChange={this.handleChangefileData2}  value={this.state.img2} defaultValue={this.state.img2}  />


          </FormGroup>
          <FormGroup className="col-md-4">
            <Label for="status"><span style={{fontWeight:600}}>Status / {produto.status}</span></Label>
            <Input type="select" name="estado" id="estado" onChange={this.handleChangeStatus} >
            <option value={produto.status}>Selecione...</option>
    <option value="0">Rascunho</option>
    <option value="1">Publicado</option>   
            </Input>
          </FormGroup>
         
        </div>
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" href="/produtos-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  renderModal2 = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const produto = this.state.produtos[this.state.selectedPost];
      return (
      
          <Form name="handleDelete" onSubmit={this.handleDelete}>
          <ModalHeader><span style={{fontWeight:600}}>Excluir Unidade</span></ModalHeader>
          <ModalBody>
          Confirme a exclusão do Produto clicando em excluir ou cancelar.
            <br /><br />
            
           <label htmlFor="nome"><span style={{fontWeight:600}}>{produto.nome}</span></label>             
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="danger" type="submit">Excluir</Button>{' '}
            <Button outline size="sm" color="danger" href="/produtos-admin">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  render() {

    const { modal2, produtos } = this.state;

    const requestSearch = (searchedVal) => {
      const filteredRows = produtos.filter((row) => {
          return row.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())});
      if (searchedVal.length < 1) {
          this.setState({filter: produtos});
      }
      else {
        this.setState({filter: filteredRows});
      }
    };

  return (
    <>
      <GerenciadorNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Produtos</h1>
          </div>

             <Row>
            <Col sm style={{textAlign:"right"}}>
 <Button
                size="sm"
                color="success"
                onClick={this.toggle}
                >
            novo produto
              </Button>       
           
            </Col>
          </Row>

          <br />


          <form>
<Row style={{marginTop:0}}>
<Col>    


<InputGroup>
<InputGroupAddon addonType="prepend">
<InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
</InputGroupAddon>
<Input type="search" placeholder="Digite o nome do produto..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
</InputGroup>



</Col>                   
</Row>
</form>

<br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Novo Produto</span></ModalHeader>
          <ModalBody>
            Preencha o formulário com os dados do Produto e clique em salvar ou cancelar.
            <br /><br />
            
                 



<label htmlFor="categoria"><span style={{fontWeight:600}}>Categoria</span></label>
        <Input type="select" name="categoria" id="categoria" onChange={this.handleChangeCategoria}>
        <option value="">Selecione...</option>

            {this.state.categorias.map(categoria => (
               categoria.status === "3" 
               ?
               <option style={{display:"none"}} key={categoria._id} value={categoria._id}>{categoria.nome}</option>
               : 
               <option key={categoria._id} value={categoria._id}>{categoria.nome}</option>
           
             ))}                  
                
        </Input>
        <br />


        <label htmlFor="nome"><span style={{fontWeight:600}}>Produto</span></label>
                  <Input                          
                  name="nome"
                  id="nome"
                  type="text"
                  required="required"
                  onChange={this.handleChangeNome}
                   />

                   <br />

       
        <FormGroup>
          <Label for="desc"><span style={{fontWeight:600}}>Descrição</span></Label>
          <Input 
          type="textarea" 
          id="desc" 
          required="desc" 
          onChange={this.handleChangeDesc}         
          />
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
          <Label for="boots"><span style={{fontWeight:600}}>Tokens</span></Label>
          <Input 
          type="text" 
          id="boots" 
          name="boots"
          onChange={this.handleChangeBoots}           
          />
          </FormGroup>
          <FormGroup className="col-md-6">
          <Label for="estoque"><span style={{fontWeight:600}}>Estoque</span></Label>
          <Input 
          type="text" 
          id="estoque" 
          name="estoque"
          required="required" 
          onChange={this.handleChangeEstoque}           
          />
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="img"><span style={{fontWeight:600}}>Imagem</span></Label>
            <input type="file" name="img" id="img" onChange={this.handleChangefileData} required />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="img"><span style={{fontWeight:600}}>Imagem2</span></Label>
            <input type="file" name="img2" id="img2" onChange={this.handleChangefileData2} />
          </FormGroup>
          <FormGroup className="col-md-4">
            <Label for="status"><span style={{fontWeight:600}}>Status</span></Label>
            <Input type="select" name="status" id="status" onChange={this.handleChangeStatus} >
            <option value="">Selecione...</option>
    <option value="0">Rascunho</option>
    <option value="1">Publicado</option>   
            </Input>
          </FormGroup>
        </div>

     
        
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>


        <Modal isOpen={this.state.modal2} className="modal-lg">
        {this.renderModal(this.state.produtos[this.state.selectedPost])}
        </Modal>

        <Modal isOpen={this.state.modal3} className={this.props.className}>
        {this.renderModal2(this.state.produtos[this.state.selectedPost])}
        </Modal>


        

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th>Categoria</th>  
          <th>Nome</th>
          <th>Descrição</th>  
          <th>Tokens</th>  
          <th>Estoque</th>  
          <th>Status</th> 
          <th width="5%">&nbsp;</th>  
          <th width="5%">&nbsp;</th>  
        </tr>
      </thead>


      <tbody>
   {this.renderUnidades()}
        
      </tbody>
    </Table>
<hr />

      
      </Container>
     
        <DemoFooter />
        </div>
      </div>
    </>
  );
}}

export default withRouter(ProdutosAdmin);
