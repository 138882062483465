import React, { useEffect, useState } from "react";
import axios from 'axios';

// reactstrap components
import {
  Button,
  Input,  
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  InputGroupAddon ,
  InputGroupText,

} from "reactstrap";


function SectionBusca() {

  const [produtos, setProdutos] = useState([]);
  const [filter, setFilter] = useState([]);


  React.useEffect(() => {
    const fetchData = async () => {
        const result = await axios(
            'https://sebstoreapi.app.br/api/produto/'
        );
        setProdutos(result.data);
        setFilter(result.data);
    }
    fetchData()
    
}, []);

  const requestSearch = (searchedVal) => {
    const filteredRows = produtos.filter((row) => {
        return row.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
        setFilter(produtos)
    }
    else {
        setFilter(filteredRows)
    }
  };
  

  
  return (
    <>
      <div className="section section-buttons">
        <Container>
        <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> | Busca de produtos
          </div>
          <div style={{fontWeight:600,}}>
            <h1>Buscar produtos</h1>
          </div>
<br />
       
          <div>
          <form>
        <Row style={{marginTop:0}}>
          <Col>    


          <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
        </InputGroupAddon>
        <Input type="search" placeholder="Digite o nome do produto..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
      </InputGroup>
        
   
   
          </Col>                   
        </Row>
      </form>
          </div>
<hr />

        <Row>

        {filter.map(item => (
           item.status === "3" 
           ?
           <Col sm style={{display:"none"}}></Col>
           :  
                     
<Col sm key={item._id}>
<Card style={{width: '15rem'}}>
  <div style={{padding:15, fontWeight:400,}}>
  <img src={(`https://sebstoreapi.app.br/uploads/${item.img}`)} style={{height:150, width:"100%"}} />

  
  </div>
  <div style={{paddingLeft:15, paddingRight:15, fontWeight:600,}}>         
  {item.nome}
  </div>
  <div style={{paddingLeft:15, paddingRight:15, fontWeight:400, whiteSpace:"nowrap", width:"18em", overflow:"hidden", textOverflow:"ellipsis"}}>         
  {item.desc}
  </div>
  <div style={{paddingLeft:15, marginTop:15, marginBottom:15, paddingRight:15, fontWeight:400,}}>  
  <Row>
    <Col>
    <span style={{fontWeight:600, fontSize:22, color:"#F5593D"}}>{item.boots} <i className="nc-icon nc-spaceship"></i></span>
    </Col>
    <Col>
    <Button
  className="btn-round"
  color="danger"
  href={`/produto/${item._id}`}
  style={{width:"100%"}} 
  >
Trocar
</Button>
    </Col>
  </Row>
  </div>
</Card>
</Col>


                        ))}
        
        </Row>
      
        

         

        
          
        </Container>
      </div>
    </>
  );
}

export default SectionBusca;
