import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";



// reactstrap components
import { Container } from "reactstrap";
// core components

class IndexHeader extends Component {

  constructor(props) {
    super(props);   
    this.state = {     
      boots: [],
      tipoboots: [],
      trocas:[],
      user:[],
      selectedPost: null,
      total:"0",
      total2:"0",
    };
  }



  async componentDidMount() {

    const user = JSON.parse(localStorage.getItem('user'));
    
    await axios.get(`https://sebstoreapi.app.br/api/boots-user/${user._id}`)
    .then((response) => {
      const boots = response.data;
      this.setState({
        total:boots.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
   
    })

    await axios.get(`https://sebstoreapi.app.br/api/troca-user/${user._id}`)
    .then((response) => {
      const trocas = response.data;
      this.setState({  
        total2:trocas.reduce((acumulado, produto) =>  parseInt(acumulado) + parseInt(produto.boots), 0)

      })
    })
    
   }



    render() {

      const { total, total2 } = this.state;

      const bootstotal = total - total2;

          // Este irá produzir o mesmo resultado porem usando uma Arrow Function
 
       
    const user = JSON.parse(localStorage.getItem('user'));
   

  
    return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg_login.jpg").default + ")",
        }}
      > 
        <div className="filter" />
        <div className="content-center">
          <Container style={{marginTop:-200}}>
            <div className="title-brand">
              <h1 ><span style={{fontWeight:600, color:"#fff"}}>Olá {user.nome}, seu saldo é de  {bootstotal} Tokens</span></h1>     
            </div>
            <h2 className="presentation-subtitle text-center">
              Veja algumas opções de produtos que você pode trocar pelos seus Tokens!
            </h2>
           
          </Container>
        </div>
       
      
      </div>
    </>
  );
}

  }

export default withRouter(IndexHeader);
