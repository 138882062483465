import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";



// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import {
  Alert,
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label
} from "reactstrap";


class SectionVouchers extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangePalavraChave = this.handleChangePalavraChave.bind(this);
    this.state = { 
      vouchers: {},  
      vouchers2: {}, 
      vouchers_id:{},
      vouchers_tokens:{},
      vouchers_palavra:{},
      palavrachave: "",
    };


  }

  componentDidMount() {
}

  handleChangePalavraChave = (event) => {
    event.persist();
    this.setState({ palavrachave: event.target.value });
  }

  handleUpdate = async event => {
    event.preventDefault();  
    await axios.get(`https://sebstoreapi.app.br/api/vouchers-consulta/${this.state.palavrachave}`)
    
        .then(res => {
          
          const vouchers_tokens = res.data.tokens;
          const vouchers_palavra = res.data.palavrachave;
          const vouchers_id = res.data._id;
          const vouchers = res.data.status;
          this.setState({ vouchers, vouchers_id, vouchers_tokens });

          console.log(vouchers);

          if(vouchers === "0"){
            alert('Voucher inativo!');
          }else{
                     
            const userSession = JSON.parse(localStorage.getItem('user'));
            console.log(vouchers_id);
            console.log(userSession._id);
            console.log(vouchers_palavra);
            axios.get(`https://sebstoreapi.app.br/api/vouchers-users-consulta/${vouchers_palavra}/${userSession._id}`)
    
            .then(response => {

              const vouchers2 = response.data;
              this.setState({ vouchers2 });
              console.log(vouchers2);


              if(vouchers2 === null){
                axios.post(`https://sebstoreapi.app.br/api/vouchers-users`, { 
                  user: userSession._id,
                  voucher: vouchers_id,
                  palavrachave: this.state.palavrachave,
                  status: "1",
                })
                .then(res => {
                  
                  axios.post(`https://sebstoreapi.app.br/api/boots`, { 
                    user: userSession._id,
                    tipoboots: "6518571eb5f43bf9ec8a548f",
                    boots: vouchers_tokens,
                  })
                  .then(res => {
    
                
                    alert('Parabéns! Seu Voucher foi validado. Em alguns minutos você receberá novos Tokens! Aguarde... Apenas 1 Voucher será validado');
                    this.props.history.push("/index");
                    window.location.reload();
                    
                  })
                  .catch(function (error) {
                    alert('Ocorreu algum erro!');
                    console.log(error);
                  });
              
                })
                .catch(function (error) {
                  alert('Ocorreu algum erro!');
                  console.log(error);
                });
              }else{
                alert('Esse Voucher já foi utilizado por você!');  
              };
      
            })
            .catch(function (error) {
             
              console.log(error);
          });
    



          };

         

        
          
         
        })
        .catch(function (error) {
          alert('Voucher inixistente!');
          console.log(error);
      });

    }
 

 
 

  render() {

    const { total, total2 } = this.state;

  return (
    <>
      <IndexNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
      <div style={{fontWeight:400,marginTop:60}}>
            <a href="/index" style={{fontWeight:600,}}>Página Principal</a> | Vouchers
          </div>
         
        

          <br />


          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <label htmlFor="sendboots"><span style={{fontWeight:600}}>Voucher</span></label>
                  <Input
                  placeholder="Digite seu voucher..."
                  defaultValue="Digite seu voucher..."
                  value={this.state.palavrachave}
                  name="palavrachave"
                  id="palavrachave"
                  type="text"
                  required="required"
                  onChange={this.handleChangePalavraChave}
                   />

                   <br />

                   <div style={{padding:0}}>
            <Button size="sm" color="success" type="submit">Validar Voucher</Button>
            </div>

          </Form>
          
         


   

     
<hr />


      
      </Container>
     

        </div>
      </div>
    </>
  );
}}

export default withRouter(SectionVouchers);
