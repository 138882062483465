
import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { FiX } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import api from "../services/api.js";


// core components
import GerenciadorNavbar from "components/Navbars/GerenciadorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container,
  Row,
  Col,  
  Table,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";


class SendBoots extends Component {

 
  constructor(props) {
    super(props);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangeTipoBoots = this.handleChangeTipoBoots.bind(this);
    this.handleChangeBoots = this.handleChangeBoots.bind(this);
  
    this.state = {  
      boots:[],
      users:[],
      unidade:[],
      tipobootslist: [],
      filter:[],
      modal: false,
      modal2: false,
      modal3: false,
      user:  props.user,
      tipoboots: props.tipoboots,
      sendboots: props.sendboots,
      selectedPost: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggle2 = i => {
    this.setState({
      modal2: !this.state.modal2,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

  toggle3 = i => {
    this.setState({
      modal3: !this.state.modal3,
      selectedPost: i // When a post is clicked, mark it as selected
    });
  };

   componentDidMount() {

     axios.get(`https://sebstoreapi.app.br/api/boots`)
    .then((response) => {
      const boots = response.data;
      const filter = response.data;
      this.setState({
        boots, filter
      })
    })

     axios.get(`https://sebstoreapi.app.br/api/user`)
    .then((response) => {
      const users = response.data;
      this.setState({
        users
      })
    })     

     axios.get(`https://sebstoreapi.app.br/api/tipo-boots`)
    .then(res => {
      const tipobootslist = res.data;
      this.setState({ tipobootslist });
    })
            
  }

  handleChangeUser = (event) => {
    event.persist();
    this.setState({ user: event.target.value });
  }

  handleChangeTipoBoots = (event) => {
    event.persist();
    this.setState({ tipoboots: event.target.value });
  }

  handleChangeBoots = (event) => {
    event.persist();
    this.setState({ sendboots: event.target.value });
  }

  
  handleSubmit = event => {
    event.preventDefault();   


    axios.post(`https://sebstoreapi.app.br/api/boots`, { 
      user: this.state.user,
      tipoboots: this.state.tipoboots,
      boots: this.state.sendboots,
    })
    .then(res => {

  
      alert('Tokens enviado com sucesso!');
      this.props.history.push("/send-boots");
      window.location.reload();
    })
    .catch(function (error) {
      alert('Ocorreu algum erro!');
      console.log(error);
     });

     

      
    }

    handleUpdate = event => {
      event.preventDefault();  

      if (this.state.selectedPost !== null) {
        const boot = this.state.boots[this.state.selectedPost];
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://sebstoreapi.app.br/api/boots/${boot._id}`, {
        user: this.state.user,
        tipoboots: this.state.tipoboots ,
        boots: this.state.sendboots, 
        }, config)
          .then(res => {
            alert('Tokens alterado com sucesso!');
            this.props.history.push("/send-boots");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }

      }


      handleDelete = event => {
        event.preventDefault(); 
        if (this.state.selectedPost !== null) {
          const boot = this.state.boots[this.state.selectedPost];

          axios.delete(`https://sebstoreapi.app.br/api/boots/${boot._id}`)
          .then(res => {
            alert('Tokens excluídos com sucesso!');
            this.props.history.push("/send-boots");
            window.location.reload();
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      
        }};

      


        renderSendBoots() {
      return this.state.filter.map((tokens, i) => {

        //console.log();
        console.log(JSON.stringify(tokens.user));
         
          return (
            <tr key={tokens._id}>    
            <td> {moment(tokens.createdAt).format('DD/MM/YYYY')}</td>  
            {tokens.user === null
            ?
            <td></td>
            :
            <td>{tokens.user.nome}</td>            
          }   

{tokens.user === null
            ?
            <td></td>
            :
            <td>{tokens.user.email}</td>            
          }  

{tokens.user === null
            ?
            <td></td>
            :
            <td>{tokens.tipoboots.nome}</td>            
          }  
            
            <td>{tokens.boots}</td>

            <td><Button
                    size="sm"
                    color="info"
                    onClick={() => this.toggle2(i)}
                    
                    >
                <FiEdit2 />
                  </Button></td> 
                  <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => this.toggle3(i)}
                    >
                <FiX />
                  </Button>
                  </td>  
                  
            </tr>
          );
      });
  }
  
  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const boot = this.state.boots[this.state.selectedPost];
      return (
      
          <Form name="handleUpdate" onSubmit={this.handleUpdate}>
          <ModalHeader><span style={{fontWeight:600}}>Alterar Tokens enviado</span></ModalHeader>
          <ModalBody>
          Utilize o formulário para editar informações do Envio e clique em salvar ou cancelar.
            <br /><br />
            
         
<label htmlFor="user"><span style={{fontWeight:600}}>Colaborador</span></label>
        <Input type="select" name="user" id="user" onChange={this.handleChangeUser}>
        <option value={boot.user._id}>Selecione...</option>

            {this.state.users.map(user => (
             user.status === "3" 
             ?
             <option style={{display:"none"}} key={user._id} value={user._id}>{user.nome} - {user.email}</option>
             : 
             <option key={user._id} value={user._id}>{user.nome} - {user.email}</option>
             ))}                   
                
        </Input>
        <br />

        <label htmlFor="tipoboots"><span style={{fontWeight:600}}>Tipo</span></label>
        <Input type="select" name="tipoboots" id="tipoboots" onChange={this.handleChangeTipoBoots}>
        <option value={boot.tipoboots._id}>Selecione...</option>
            {this.state.tipobootslist.map(tipo => (
             tipo.status === "3" 
             ?
             <option style={{display:"none"}} key={tipo._id} value={tipo._id}>{tipo.nome}</option>
             : 
             <option key={tipo._id} value={tipo._id}>{tipo.nome}</option>
             ))}         
        </Input>
        <br />

        <label htmlFor="sendboots"><span style={{fontWeight:600}}>Quantos Tokens?</span></label>
                  <Input
                  placeholder={boot.boots}
                  defaultValue={boot.boots}
                  value={this.state.sendboots}
                  name="sendboots"
                  id="sendboots"
                  type="text"
                  required="required"
                  onChange={this.handleChangeBoots}
                   />

                   <br />



            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" href="/send-boots">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  renderModal2 = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedPost !== null) {
      const boot = this.state.boots[this.state.selectedPost];
      return (
      
          <Form name="handleDelete" onSubmit={this.handleDelete}>
          <ModalHeader><span style={{fontWeight:600}}>Excluir Tokens</span></ModalHeader>
          <ModalBody>
          Confirme a exclusão dos Tokens clicando em excluir ou cancelar.
            <br /><br />
            
           <label htmlFor="nome"><span style={{fontWeight:600}}>{boot.user.nome}</span></label>             
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="danger" type="submit">Excluir</Button>{' '}
            <Button outline size="sm" color="danger" href="/send-boots">Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        
      );
    }
  }


  render() {

    const { modal2, boots, filter } = this.state;


    const requestSearch = (searchedVal) => {
      const filteredRows = boots.filter((row) => {
          return row.user.nome.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) || row.user.email.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      });
      if (searchedVal.length < 1) {
          this.setState({filter: boots});
      }
      else {
        this.setState({filter: filteredRows});
      }
    };

  return (
    <>
      <GerenciadorNavbar />

     
      <div className="main">
      <div className="section section-buttons">
      <Container>
 
                <div style={{fontWeight:600, marginTop:50}}>
            <h1>Enviar Tokens</h1>
          </div>

       <div>
       


      </div>
          <Row>
            <Col sm style={{textAlign:"right"}}>
 <Button
                size="sm"
                color="success"
                onClick={this.toggle}
                >
            Enviar Tokens
              </Button>       
           
            </Col>
          </Row>

          <br />


          <form>
<Row style={{marginTop:0}}>
<Col>    


<InputGroup>
<InputGroupAddon addonType="prepend">
<InputGroupText><i className="nc-icon nc-zoom-split"></i></InputGroupText>
</InputGroupAddon>
<Input type="search" placeholder="Digite o nome ou e-mail do colaborador..." onChange={(e) => requestSearch(e.target.value)} style={{width:"90%"}}  />
</InputGroup>



</Col>                   
</Row>
</form>

<br />
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
          <Form name="handleSubmit" onSubmit={this.handleSubmit}>
          <ModalHeader><span style={{fontWeight:600}}>Novo Envio de Tokens</span></ModalHeader>
          <ModalBody>
           Selecione o Colaborador e o tipo de Tokens depois clique em salvar ou cancelar.
            <br /><br />
            

      <label htmlFor="user"><span style={{fontWeight:600}}>Colaborador</span></label>
        <Input type="select" name="user" id="user" onChange={this.handleChangeUser}>
        <option value="">Selecione...</option>
            {this.state.users.map(user => (
             user.status === "3" 
             ?
             <option style={{display:"none"}} key={user._id} value={user._id}>{user.nome}</option>
             : 
             <option key={user._id} value={user._id}>{user.nome} - {user.email}</option>
             ))}         
        </Input>
        <br />

        <label htmlFor="tipoboots"><span style={{fontWeight:600}}>Tipo</span></label>
        <Input type="select" name="tipoboots" id="tipoboots" onChange={this.handleChangeTipoBoots}>
        <option value="">Selecione...</option>
            {this.state.tipobootslist.map(tipo => (
            tipo.status === "3" 
            ?
            <option style={{display:"none"}} key={tipo._id} value={tipo._id}>{tipo.nome}</option>
            : 
            <option key={tipo._id} value={tipo._id}>{tipo.nome}</option>
             ))}         
        </Input>
        <br />


        <label htmlFor="sendboots"><span style={{fontWeight:600}}>Quantos Tokens?</span></label>
                  <Input
                  name="sendboots"
                  id="sendboots"
                  type="text"
                  required="required"
                  onChange={this.handleChangeBoots}
                   />

                   <br />

       
            
            
          </ModalBody>
          <ModalFooter>
          <div style={{padding:10}}>
            <Button size="sm" color="success" type="submit">Salvar</Button>{' '}
            <Button size="sm" color="danger" onClick={this.toggle}>Cancelar</Button>
            </div>
          </ModalFooter>
          </Form>
        </Modal>


        <Modal isOpen={this.state.modal2} className="modal-lg">
        {this.renderModal(this.state.boots[this.state.selectedPost])}
        </Modal>

        <Modal isOpen={this.state.modal3} className={this.props.className}>
        {this.renderModal2(this.state.boots[this.state.selectedPost])}
        </Modal>


        

          <Table striped>
          <thead>
        <tr>
         
          <th>Data</th>
          <th>Colaborador</th>  
          <th>E-mail</th>  
          <th>Tipo</th> 
          <th>Tokens</th> 
          <th width="5%">&nbsp;</th> 
          <th width="5%">&nbsp;</th>   
        </tr>
      </thead>


      <tbody>
   {this.renderSendBoots()}
        
      </tbody>
    </Table>
<hr />


      
      </Container>
     
        <DemoFooter />
        </div>
      </div>
    </>
  );
}}

export default withRouter(SendBoots);
