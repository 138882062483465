/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import logo from "../../assets/img/logo.png";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Modal 
} from "reactstrap";

function ExamplesNavbar() {
  const [largeModal, setLargeModal] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"            
            title="SEB Store"
            tag={Link}
          >
            <img src={logo} style={{height:40}} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink style={{cursor:"pointer"}} onClick={() => setLargeModal(true)}>
                Precisando de Ajuda?
              </NavLink>
            </NavItem>            
           
            {/* <NavItem>
              <Button
                className="btn-round"
                color="danger"
                href="/cadastro"
              >
                <i className="nc-icon nc-spaceship"></i> Cadastre-se!
              </Button>
            </NavItem> */}
          </Nav>
        </Collapse>

        <Modal
        isOpen={largeModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        toggle={() => setLargeModal(false)}
      >
        <div className="modal-header">
          <h1 className="modal-title" style={{fontWeight:600}} id="myLargeModalLabel">
            Precisando de Ajuda?
          </h1>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setLargeModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{fontWeight:"400"}}>

        Bem-vindo ao SEB Store, o marketplace oficial do Grupo SEB! Aqui, oferecemos a você uma experiência única de recompensas. Ao acessar nossa plataforma com seus dados de acesso corporativo, você receberá incríveis 80 Tokens de Onboarding para trocar por produtos exclusivos.<br /><br />

Nossa plataforma é fácil de usar e intuitiva. Tudo o que você precisa fazer é escolher o produto que deseja, clicar em "Trocar Tokens" e selecionar a unidade na qual gostaria de receber seu prêmio. Depois disso, é só acompanhar o status da troca.<br /><br />

Estamos empolgados em tê-lo conosco no SEB Store e esperamos que você desfrute das recompensas que preparamos para você. Comece a explorar agora e troque seus Tokens por produtos incríveis!<br /><br />&nbsp;
        </div>
      </Modal>
      </Container>
    </Navbar>
    
  );
}

export default ExamplesNavbar;
