import React from "react";
import classnames from "classnames";
import { SlPower } from "react-icons/sl";

// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import logo from "../../assets/img/logo.png";

function GerenciadorNavbar() {

  const [navbarColor, setNavbarColor] = React.useState("#FFF");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const [user, setUser] = React.useState(() => {
    // getting stored value
    const saved = localStorage.getItem("user");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  React.useEffect(() => {

  

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 0 ||
        document.body.scrollTop > 0
      ) {
        setNavbarColor("#FFF");
      } else if (
        document.documentElement.scrollTop < 0 ||
        document.body.scrollTop < 0
      ) {
        setNavbarColor("#FFF");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/gerenciador"
            title="SEB Store Gerenciador"
          >
            
          <img src={logo} style={{height:40}} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
            <UncontrolledDropdown>
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          className="btn-round"
          color="secondary"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
         Menu principal
        </DropdownToggle>
        <DropdownMenu aria-labelledby="dropdownMenuButton">
          <br />
          <DropdownItem href="/trocas-admin">
          Pedidos de Trocas
          </DropdownItem>
      
          <DropdownItem href="/filtro">
          Relatório de Pedidos
          </DropdownItem>
          <hr />
          <DropdownItem href="/categoria-admin">
          Categorias
          </DropdownItem>
          <DropdownItem href="/produtos-admin">
          Produtos
          </DropdownItem>
          <hr />
          <DropdownItem href="/tipos-boots-admin">
          Tipos de <i className="nc-icon nc-spaceship"></i>Tokens
          </DropdownItem>
          <DropdownItem href="/send-boots">
          Enviar <i className="nc-icon nc-spaceship"></i>Tokens
          </DropdownItem>
          <hr />
          <DropdownItem href="/voucher-admin">
          Vouchers
          </DropdownItem>
          <hr />
          <DropdownItem href="/unidade-negocio-admin">
          Unidades de Negócios
          </DropdownItem>
          <DropdownItem href="/unidade-admin">
          Unidades
          </DropdownItem>
          <DropdownItem href="/user-admin">
          Usuários
          </DropdownItem>
          <br />
        </DropdownMenu>
      </UncontrolledDropdown>
            </NavItem>

            <NavItem>
              <Button
                className="btn-round"
                color="secondary"
                href="/index"
                target="_Blank"
                  >
                SEB Store
              </Button>
            </NavItem>
            
            <NavItem>
           
              <NavLink
                data-placement="bottom"
                href="/sair"
              >
                <SlPower />
              </NavLink>
            </NavItem>

            



          </Nav>
        </Collapse>
       
      </Container>
    </Navbar>
  );
}

export default GerenciadorNavbar;
